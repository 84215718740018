.App {
  --site-orange: #f07046;
  --site-yellow: #f1cf48;
  --site-darkyellow: #dfbe35;
  --site-olivegreen: #95af3f;
  --site-oceanblue: #478a69;
  --site-greyblue: #467f8a;
  --site-white: #fff;
  --site-lightgrey: #f9f9f9;
  --site-grey: #505050;
  --site-darkgrey: #102e33;
  --site-black: #0f2a2f;
  /* COLOR CODE
    colors are as follows:
    - yellow = calories
    - olivegreen = protein
    - oceanblue = carbs
    - greyblue = fat
  */

  text-align: center;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  background-color: var(--site-darkgrey);
  color: #e7e7e7;
}

.delete-btn-container {
  border: 2px solid white;
  border-radius: 5px;
  width: 100px;
}

.delete-btns {
  height: 30px;
  margin-top: -15px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
}

.delete-btn-container .cancel {
  color: #dc4c64;
}

.delete-btn-container .confirm {
  color: #14a44d;
  margin-left: 10px;
}

.basic-header {
  font-weight: bold;
  border: 2px solid white;
  border-radius: 10px;
  max-width: 300px;
  margin: 30px auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
