.ingredient-list-btns {
  height: 25px;
}

#food-display .ingredient-serving-box {
  border: 2px solid white;
  border-radius: 5px;
  width: 90px;
  margin: 0 auto;
}

#food-display .serving-box-text {
  margin: -15px auto 0 auto;
}
