.each-recipe .select {
  border: 4px solid white;
  border-radius: 10px;
  border-style: dashed;
}

.each-recipe .unselect {
  border: 4px solid white;
  border-radius: 10px;
  background-color: var(--site-darkgrey);
}

.recipe-caret {
  height: 30px;
  margin: 0 30px;
}

#recipe-totals span {
  height: 30px;
  width: 70px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 400;
}

#total-weight {
  border: 2px solid #666;
}

#recipe-total-cal {
  border: 2px solid var(--site-yellow);
}

#recipe-total-p {
  border: 2px solid var(--site-olivegreen);
}

#recipe-total-c {
  border: 2px solid var(--site-oceanblue);
}

#recipe-total-f {
  border: 2px solid var(--site-greyblue);
}

.add-ingredient-form {
  border-radius: 10px;
  margin-bottom: 20px;
}

.recipe-btns {
  height: 20px;
}

.recipe-btns:hover {
  color: #dc4c64;
}

.selected-btn {
  color: #dc4c64;
}

@media screen and (max-width: 525px) {
  .recipe-caret {
    margin: 0 10px;
  }
}
