.user-form {
  background-color: var(--site-black);
}

.form-meal {
  background-color: var(--site-grey);
  color: white;
  height: 37px;
  border: 1px solid white;
}

#input-name,
#input-servings {
  background-color: var(--site-grey);
}

.page-container .input-cal {
  background-color: var(--site-yellow);
}

.page-container .input-protein {
  background-color: var(--site-olivegreen);
}

.page-container .input-carbs {
  background-color: var(--site-oceanblue);
}

.page-container .input-fat {
  background-color: var(--site-greyblue);
}

.page-container .form-box-grey {
  background-color: var(--site-grey);
}

@media screen and (max-width: 421px) {
  #food-input-unit {
    margin: 0px;
  }
}
