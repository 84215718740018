.progress-container {
  height: 100px;
  min-width: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: var(--site-lightgrey);
}

.progress-circle {
  height: 200px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pie {
  width: 150px;
  display: inline-grid;
  place-content: center;
  margin: 5px;
  font-size: 25px;
}

#pie-center {
  width: 180px;
  height: 180px;
  position: absolute;
  rotate: 270deg;
  border-radius: 50%;
  border: 10px solid var(--site-lightgrey);
  z-index: 2;
}

.outer-circle {
  position: absolute;
  border-radius: 50%;
  inset: 0;
  rotate: 270deg;
}
