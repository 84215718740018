.recipe-list .select {
  border: 4px solid var(--site-grey);
  border-radius: 10px;
  border-style: dashed;
}

.recipe-list .unselect {
  border: 4px solid var(--site-grey);
  border-radius: 10px;
  background-color: var(--site-grey);
}
