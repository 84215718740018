.dashboard-big-display {
  min-width: 200px;
  height: 250px;
  color: var(--site-black);
  position: relative;
  overflow: hidden;
  display: none;
}

.dashboard-macro-display {
  display: flex;
  flex-direction: row;
}

.dashboard-macro-display .cal {
  background-color: var(--site-yellow);
}

.dashboard-macro-display .cal-dark {
  background-color: var(--site-darkyellow);
}

.dashboard-macro-display .protein {
  background-color: var(--site-olivegreen);
}

.dashboard-macro-display .carbs {
  background-color: var(--site-oceanblue);
}

.dashboard-macro-display .fat {
  background-color: var(--site-greyblue);
}

.dashboard-big-display .ring-cal {
  border: 3px solid var(--site-darkyellow);
}

.dashboard-big-display .ring-protein {
  border: 3px solid #8aa12e;
}

.dashboard-big-display .ring-carbs {
  border: 3px solid #427f4d;
}

.dashboard-big-display .ring-fat {
  border: 3px solid #417566;
}

.dashboard-big-display .p {
  background-color: #8aa12e;
}

.dashboard-big-display .c {
  background-color: #427f4d;
}

.dashboard-big-display .f {
  background-color: #417566;
}

.active-macro {
  display: flex;
}

.dashboard-big-display span {
  font-size: 20px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  margin-left: 2px;
}

.big-display-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.ring-one {
  width: 350px;
  height: 350px;
  position: absolute;
  border-radius: 50%;
  top: -200px;
  left: -215px;
}

.ring-two {
  width: 400px;
  height: 400px;
  position: absolute;
  border-radius: 50%;
  top: -315px;
  left: -180px;
}

.dashboard-macros {
  min-height: 200px;
  background-color: var(--site-black);
  display: flex;
  align-items: center;
}

.macro-small {
  height: 50px;
  width: 130px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.macro-small-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.macro-small-text {
  margin: 0 auto;
  font-weight: 400;
  font-size: 14;
  height: 100%;
  text-align: left;
  width: 70px;
}

.macro-small-text p:nth-child(2) {
  font-weight: 900;
  margin-top: -20px;
}

.macro-percents {
  background-color: var(--site-lightgrey);
  max-width: 260px;
  margin-left: 0px;
  flex-wrap: wrap;
}

.macro-percents .percent-text {
  margin: 0 auto;
  font-weight: 400;
  font-size: 14;
  height: 100%;
  text-align: left;
  width: 80px;
}

.macro-percents .cal {
  background-color: var(--site-yellow);
  color: white;
}

.macro-percents .protein {
  background-color: var(--site-olivegreen);
  color: white;
}

.macro-percents .carbs {
  background-color: var(--site-oceanblue);
  color: white;
}

.macro-percents .fat {
  background-color: var(--site-greyblue);
  color: white;
}

.overview-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .dashboard-macros {
    max-width: 200px;
  }

  .overview-container {
    flex-direction: column;
  }

  .macro-percents {
    max-width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .macro-small {
    margin: 5px 20px;
  }
}

@media screen and (max-width: 460px) {
  .dashboard-macro-display {
    flex-direction: column;
  }
}
