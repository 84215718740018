.page-container {
  min-height: 85vh;
}

.page-container .recipe-form-grey {
  background-color: var(--site-grey);
}

.recipe-form {
  background-color: var(--site-black);
}

.form-card {
  display: none;
  min-height: 325px;
  position: relative;
}

.form-card.active {
  display: block;
}

.recipe-form button {
  min-width: 100px;
  display: flex;
  justify-content: center;
}

.recipe-form-btns .next {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
}

.recipe-form-btns .previous {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
}

.recipe-form-btns .prevent-next {
  border: 2px solid grey;
  color: grey;
  pointer-events: none;
}

#recipe-form-grey {
  background-color: var(--site-grey);
}

.review-recipe {
  border: 2px solid white;
  border-radius: 5px;
}

.review-recipe-box {
  min-height: 30px;
  border: 2px solid white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: white;
  margin-bottom: 10px;
  background-color: var(--site-grey);
}

.review-title {
  margin: 0 auto;
  color: white;
  text-align: left;
}

.form-card .review-tip {
  height: 25px;
}

.form-card .review-tip:hover {
  color: #3b71ca;
}

.xs-header {
  display: none;
}

@media screen and (max-width: 421px) {
  .form-unit {
    margin-top: 0px;
  }
}
