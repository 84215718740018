.food-table {
  background-color: var(--site-black);
}

.food-caret {
  height: 30px;
  margin: 0 30px;
}

.unselect {
  background-color: var(--site-grey);
  border: 4px solid var(--site-grey);
  border-radius: 10px;
}

.food-table .select {
  border: 4px solid var(--site-grey);
  border-radius: 10px;
  border-style: dashed;
}

@media screen and (max-width: 421px) {
  .food-caret {
    margin: 0 10px;
  }
}
