.dash-log-container {
  color: var(--site-black);
}

.dash-log-items {
  background-color: var(--site-lightgrey);
  height: 260px;
  overflow: scroll;
}

.dash-log-items tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.dash-log-items tr:hover {
  font-weight: bold;
  border-top: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
}

#dash-macro-selector {
  border: 0px;
  width: 110px;
}

.dash-log-items path:hover {
  color: #3b71ca;
}

.tutorial-video {
  width: 100%;
  height: 315px;
}
